@use '@angular/material' as mat;
@import '../node_modules/@angular/material/theming';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$light-theme-primary: mat.define-palette(mat.$blue-palette);
$light-theme-accent: mat.define-palette(mat.$blue-grey-palette, A200, A100, A400);
$light-theme-warn: mat.define-palette(mat.$orange-palette);

$light-theme: mat.define-light-theme($light-theme-primary, $light-theme-accent, $light-theme-warn);

@include mat.all-legacy-component-themes($light-theme);

// Custom colors
$custom-colors: (
  light-grey: mat.get-color-from-palette(mat.$gray-palette, 800),
);


// Custom flex containers, fonts, icons
.flex-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  list-style: none;
}

#homepage-info, #homepage-options, #request-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
#homepage-info div, #homepage-options div {
  flex-basis: 1000px;
  color: map-get($custom-colors, light-grey);
  margin-top: 20px;
}
#homepage-options .flex-item {
  min-width: 250px;
  color: map-get($custom-colors, light-grey);
  cursor: pointer;
}
#homepage-options h2 {
  text-align: center;
}
#homepage-options .mat-icon {
  font-size: 200px;
  color: #2196F3;
  margin-left: 10px;
}


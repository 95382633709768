/* You can add global styles to this file, and also import other style files */
html, body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
}

app-root {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: column;
}

.main-view, app-request-form, app-field-coord-er-request-form {
  flex: 1;
  flex-direction: row;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  padding: 0 !important;
}

.mat-drawer-container, .mat-table, .mat-paginator {
  background-color: #fafafa !important;
}

.filter-sidenav > mat-list > mat-list-item > .mat-list-item-content {
  justify-content: space-around !important;
  height: 60px !important;
}

mat-sidenav-content {
  padding-left: 15px;
}

mat-table {
  padding-right: 30px;
}

.table {
  flex: 1 1 auto;
  overflow: auto;
  max-height: 100%;
}

mat-table mat-row:nth-child(odd) {
  background-color: #eeeeee;
}

mat-row.row.mat-row:hover {
  background: rgba(0, 0, 0, 0.08);
}

mat-form-field {
  width: 100%;
}

.mat-header-row {
  position: sticky;
  top: 0;
  background: #FAFAFA;
  z-index: 9999;
}

.table .mat-cell {
  font-size: 12px;
}

.table .mat-icon {
  font-size: 18px;
}

.list-container, app-approval-list, mat-sidenav-container, mat-sidenav-content, .sidenav-content {
  flex: 1;
  flex-direction: column;
  display: flex !important;
  overflow-y: auto;
}

/*.mat-select-value-text {*/
/*white-space: normal !important;*/
/*}*/

.mat-tooltip {
  white-space: pre-line;
  font-size: 20px;
}

.mat-tooltip-blue {
  background: #2196F3 !important;
  font-size: 15px !important;
  border: 1px solid lightblue;
}

.filter-sidenav > mat-list > mat-list-item > .mat-list-item-content {
  justify-content: space-around !important;
  height: 60px !important;
  margin-top: 15px;
}

mat-list-item.filter-btn div {
  justify-content: space-between;
}

.snackbar-success {
  background-color: green !important;
}

.snackbar-error {
  background-color: darkorange !important;
  color: black !important;
}

div.mat-dialog-actions {
  justify-content: flex-end;
}

mat-table {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
mat-progress-bar.input-progress-bar {
  top: 8px;
  height: 2px;
  margin-top: -2px;
}

/*.mat-tooltip {*/
/*  white-space: pre-line !important;*/
/*}*/
